import React, {useState, useEffect} from "react";

import {AppBar, Toolbar, makeStyles, Box} from "@material-ui/core";

import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Link from "../components/Link";

import image from "../images/azomo_transparent.png";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  bottomToolbar: {
    display: "flex",
    background: "#DE2D47",
    minHeight: "5px",
    maxHeight: "30px",
    color: "#DE2D47",
  },
  root: {
    top: 0,
    bottom: 0,
    zIndex: 5,
  },
  hideNavbar: {
    "@media print": {
      display: "none",
    },
  },
  list: {
    width: 250,
    color: "white",
  },
  fullList: {
    width: "auto",
    color: "white",
  },
  btn: {
    color: "white",
    width: "100%",
    height: "100%",
  },
  nav: {
    display: "flex",
  },
  navButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    "& a": {
      color: "white",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1px",
      height: "60px",
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  blackItem: {
    background: "black",
    color: "white",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  whiteItem: {
    background: "white",
    color: "black",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Header() {
  const [width, setWidth] = useState(1280);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", handleWindowSizeChange);
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    } else {
      return null;
    }
  }, []);

  let isMobile = width <= 768;

  const [state, setState] = useState({
    top: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({...state, [anchor]: open});
  };

  const classes = useStyles();

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className={classes.listItem}>
        {[
          {text: "Startseite", link: "/"},
          {text: "Hose", link: "/pants"},
          {text: "Jacke", link: "/jackets"},
          {text: "Deine Auswahl", link: "/cart"},
        ].map((item, index) => (
          <Link key={item.text} to={item.link}>
            <ListItem
              button
              key={index}
              className={index % 2 === 0 ? classes.blackItem : classes.whiteItem}
            >
              <ListItemText
                primary={item.text}
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  return (
    <AppBar className={classes.hideNavbar} position="sticky">
      <Box className={classes.nav}>
        <Box flexGrow={1} className={classes.hideOnMobile}>
          <Link to="https://www.azomo.ch/safety/en/azomo/the-company/">
            <img className={classes.logo} alt="" src={image}></img>
          </Link>
        </Box>
        <Box flexGrow={1} className={classes.navButton}>
          <Link to={"/"}>Startseite</Link>
        </Box>
        <Box flexGrow={1} className={classes.navButton}>
          <Link to={"/pants"}>Hosen</Link>
        </Box>
        <Box flexGrow={1} className={classes.navButton}>
          <Link to={"/jackets"}>Jacken</Link>
        </Box>
        <Box flexGrow={1} className={classes.navButton}>
          <Link style={{alignSelf: "right"}} to={"/cart"}>
            Deine Auswahl
          </Link>
        </Box>
      </Box>

      {/* {isMobile ? (
        <Box display="flex">
          <Box flexGrow={4}>
            {['top'].map(anchor => (
              <React.Fragment key={anchor}>
                <Button
                  onClick={toggleDrawer(anchor, true)}
                  className={classes.btn}
                >
                  <MenuIcon style={{ marginRight: '5px' }} fontSize="large" />
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </Box>
          <Box flexGrow={8}>
            <Link to="/">
              <img alt="" src={image}></img>
            </Link>
          </Box>
        </Box>
      ) : null} */}

      {/* {!isMobile ? (
        <Box className={classes.nav}>
          <Box flexGrow={1} className={classes.navButton}>
            <Link to="/">
              <img className={classes.logo} alt="" src={image}></img>
            </Link>
          </Box>
          <Box flexGrow={1} className={classes.navButton}>
            <Link to={'/'}>Startseite</Link>
          </Box>
          <Box flexGrow={1} className={classes.navButton}>
            <Link to={'/pants'}>Hose</Link>
          </Box>
          <Box flexGrow={1} className={classes.navButton}>
            <Link to={'/jackets'}>Jacke</Link>
          </Box>
          <Box flexGrow={1} className={classes.navButton}>
            <Link style={{ alignSelf: 'right' }} to={'/cart'}>
              Deine Auswahl
            </Link>
          </Box>
        </Box>
      ) : null} */}

      <Toolbar className={classes.bottomToolbar}>
        {/* <Typography className={classes.subtitle} noWrap variant="h6">
          comfortable safety
        </Typography> */}
      </Toolbar>
    </AppBar>
  );
}
